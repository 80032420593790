<template>
  <div>
    <b-overlay :show="true">
      <b-card style="min-height: 100px" />
    </b-overlay>
  </div>
</template>
<script>
import { BCard, BOverlay } from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BOverlay,
  },
  created() {
    localStorage.clear()
  },
  mounted() {
    useApollo.auth.socialLogin({
      token: this.$route.query.code,
      provider: this.$route.name,
    }).then(response => {
      const userData = response.data.socialLogin
      localStorage.setItem('userData', JSON.stringify(userData.user))
      useJwt.setToken(userData.access_token)
      setTimeout(() => {
        this.$router.replace({ name: 'home' }).then(() => {
          this.$store.dispatch('project/STORE_SELECTED_PROJECT', null)
          // this.$store.dispatch('authEvents/onUserLogin')
          this.showSuccessMessage({
            data: {
              message: `Welcome ${userData.user.name}`,
            },
          })
        })
      }, 500)
    }).catch(error => {
      if (error.response?.data) {
        if (error.response?.data?.errors) this.$refs.loginForm.setErrors(error.response.data.errors)
        if (error.response?.data?.message) {
          this.showErrorMessage(error.graphQLErrors[0]?.extensions.reason)
        }
      } else {
        this.showErrorMessage(error.graphQLErrors[0]?.extensions.reason)
      }
    })
  },
}
</script>
